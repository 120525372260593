<template>
    <component
        :is="link ? 'a' : 'button'"
        :[attributeName]="link"
        class="vb-simple-button"
        :class="[className, {'outline': outline}]"
        :id="idName"
        type="button"
        :disabled="btnDisabled"
        @click="clickButton()"
        :style="buttonStyle"
        ref="button"
    >
        <slot></slot>
    </component>
</template>

<script setup>
import { ref, computed, watch } from 'vue'

const props = defineProps({
    invalid: { type: Boolean },
    color: { type: String, required: true, default: null },
    width: null,
    height: null,
    fontSize: null,
    outline: { type: Boolean, default: false },
    className: { type: String, default: null },
    idName: { type: String, default: null },
    isTwoClickProtect: { type: Boolean, default: false },
    link: { type: String, default: null },
    padding: null
})

const emit = defineEmits(['click'])

const backgroundColor = ref(null)
const btnDisabled = ref(false)
const attributeName = ref(null)

// Template refs
const button = ref(null)

const clickButton = () => {
    if (props.isTwoClickProtect) {
        btnDisabled.value = true
        button.value.style.opacity = "0.7"
        setTimeout(() => {
            btnDisabled.value = false
            button.value.style.opacity = ""
        }, 1000)
    }
    emit('click')
}

watch(
    () => props.invalid,
    (val) => {
        if (val) {
            btnDisabled.value = true
            backgroundColor.value = '#cacaca'
        } else {
            btnDisabled.value = false
            if (props.color) backgroundColor.value = props.color
        }
    },
    { immediate: true }
)

const buttonStyle = computed(() => {
    const style = {}

    if (props.width) style.width = props.width
    if (props.height) style.height = props.height
    if (props.fontSize) style.fontSize = props.fontSize
    if (props.padding) style.padding = props.padding

    if (props.outline) {
        style.borderColor = props.color
        style.color = props.color
    } else {
        style.backgroundColor = props.color
    }

    return style
})

// リンクが指定されている場合のみ属性を付与する
if (props.link !== null) {
    attributeName.value = 'href'
}
</script>

<style lang="scss" scoped>
.vb-simple-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #009098;
    border-radius: 4px;
    border: solid 1px #009098;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    font-size: inherit;
    line-height: 1.0;
    width: 100%;
    -webkit-appearance: unset;
    &:disabled {
        cursor: unset;
    }
    &.outline {
        background-color: #ffffff;
    }
    &.resell {
        background-color: $dark_red_color;
        border: solid 1px $dark_red_color;
        width: 170px;
    }
    &.library {
        width: 120px;
        height: 32px;
        margin: 16px auto 24px;
        padding: 11px 12px;
        box-sizing: border-box;
        border-radius: 16px;
        cursor: pointer;
        white-space: nowrap;
        font-size: 0.6rem;
        span {
            width: 12px;
            height: 12px;
            position: relative;
            margin-right: 4px;
            margin-top: -1px;
            &::before,&::after {
                display: block;
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 84%;
                height: 8%;
                margin: 0 0 0 -42%;
                background: #FFF;
            }
            &::after {
                transform: rotate(90deg);
            }
        }
    }
    &.center {
        margin: 0 auto;
    }
    &.right {
        margin-left: auto;
    }
    @media screen and (min-width: $breakpoint-md) {
        width: 320px;
        &.resell {
            width: 190px;
        }
        &.library {
            width: 200px;
            height: 40px;
            border-radius: 20px;
            font-size: 14px;
        }
    }
}
</style>
